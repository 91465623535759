// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~trix/dist/trix.css';

// Trix textarea //
trix-toolbar [data-trix-attribute='heading1'],
trix-toolbar [data-trix-button-group='file-tools'],
trix-toolbar [data-trix-attribute='code'] {
	display: none;
}

// Input styling //
input:not([type='checkbox'], [type='radio']), textarea, select {
	display: block;
	padding: 0.375rem 0.75rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.6;
	color: $black;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	:focus {
		outline: none;
		border: .3px solid $brand-main2 !important;
		border-radius: 10px;
	}

	:target {
		border: transparent;
	}
}

input, select {
	height: 40px;

	&:not([type='checkbox']) {
		width: 100%;
	}
}

textarea {
	height: 150px;
}


// Error & Success popup //
.popup {
	position: fixed;
	z-index: 999;
	top: 10vh;
	right: -100vw;
	-webkit-animation: slide 0.5s forwards;
	animation: slide 0.5s forwards;

	.popup-notification {
		border-radius: 0.5rem;
		color: white;

		&.alert-danger {
			background-color: $error;
		}

		&.alert-success {
			background-color: $valide;
		}
	}
}

@-webkit-keyframes slide {
	100% {
		right: 45px;
	}
}

@keyframes slide {
	100% {
		right: 45px;
	}
}

// Card login styling //
.card-login {
	display: flex;
	margin: 50px;
	background-color: #ffffff;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

	.card-login-left {
		display: inline-flex;
		justify-content: space-evenly;
		flex-direction: column;
		width: 50%;
		border-bottom-left-radius: 20px;
		border-top-left-radius: 20px;

		h1 {
			text-align: center;
		}

		form {
			width: 60%;
		}

		.form-group {
			margin: 30px 0;
		}

		input, textarea, select {
			border-radius: 10px;
			padding: 15px;
		}

		button {
			border-radius: 10px;
			width: 100%;
		}

		.register {
			text-align: center;
		}
	}

	.card-login-right {
		width: 50%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-bottom-right-radius: 20px;
			border-top-right-radius: 20px;
		}
	}
}

@media (max-width: 991px) {
	.card-login {
		margin: 50px 15px;

		.card-login-left {
			width: 100%;
			padding: 30px;

			form {
				width: 100%;
			}
		}

		.card-login-right {
			display: none;
		}
	}
}


.card-img-top {
	height: 300px;
	object-fit: cover;
}

.footer-logo {
	text-align: center;

	& img {
		max-width: 300px;
	}

}

.dashboard-container {
	flex-wrap: nowrap;
}

// Sidebar styling //
.dashboard-sidebar {
	height: 100vh;
	min-width: $sidebar-min-width;
	width: 16.6667%;
	flex: 0 0 16.6667%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	.logo {
		padding: 30px 0 60px;

		img {
			width: 100%;
			height: auto;
		}
	}

	ul {
		position: relative;
		width: 100%;

		.active {
			a {
				background-color: $brand-main1;
				box-shadow: $box-shadow;
				color: white;
				position: relative;

				:hover {
					color: white;
				}
			}
		}

		li {
			position: relative;
			list-style: none;
			margin-left: -40px;
			line-height: 4rem;
			transition: all 0.3s ease-in-out;

			:hover {
				background-color: lighten($brand-main1, 20%);
				color: white;
				transition: all 0.3s ease-in-out;
			}

			a {
				color: #161616;
				position: relative;
				display: flex;
				width: 100%;
				text-decoration: none;
				border-top-right-radius: 15px;
				border-bottom-right-radius: 15px;

				:hover {
					background-color: transparent;
					border: 0;
				}

				.icon {
					position: relative;
					display: block;
					min-width: 60px;
					text-align: center;
					height: 60px;
					line-height: 80px;
					margin-left: 20px;
					font-size: 1.8em;

					ion-icon {
						position: relative;
						z-index: 1;
					}
				}

				.link {
					position: relative;
					display: block;
					height: 70px;
					white-space: nowrap;
					font-size: 1.4em;
					line-height: 70px;
				}
			}
		}
	}

	.logo-mgs {
		padding: 15px;

		img {
			width: 100%;
		}
	}
}

//Dashboard background styling
.dashboard-main {
	width: 83.3333%;
	max-width: calc(100vw - #{$sidebar-min-width});

	.dashboard-content {
		background-color: white;
		box-shadow: $box-shadow;
		min-height: calc(100vh - 60px);
		border-radius: 20px;
		margin: 30px;
		padding: 30px;

		p {
			padding-bottom: 30px;
		}
	}
}

// Selector with number of results per page//
.pages-results {

	select {
		border-radius: 15px;
	}
}

// Barre de recherche //
.search-bar {

	input {
		border-radius: 15px 0 0 15px;
	}

	button {
		border-radius: 0 15px 15px 0;
	}

	span {
		border-left: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}


// Dropdown menu & Filter components //
.dropdown-menu {
	.dropdown-item {
		cursor: pointer;
	}

	.submit-filter {
		border: 0;
		box-shadow: none;

		&hover {
			color: $brand-main1;
		}
	}
}

// Modal //
.modal {
	box-shadow: $box-shadow;

	.modal-footer {
		a {
			background-color: $brand-main1;
			border: 0;
		}

		a:hover {
			background-color: lighten($brand-main1, 10%);
		}
	}
}

.modal-link {
	cursor: pointer;
	color: $brand-main1;
	text-decoration: none;
	transition: all 0.3s ease-in-out;

	&:hover {
		color: darken($brand-main1, 20%);
		text-decoration: none;
	}
}


// Cards //
.bubble-card {
	width: 100%;
	border-radius: 10px;
	margin: 60px 0;
	padding: 30px;
	background-color: #ffffff;
	box-shadow: $box-shadow;

	h2 {
		margin-bottom: 15px;
	}

	input, select {
		width: 100%;
	}
}

.column {
	width: calc(50% - 30px); // calculate 50% - margin right + margin left
	margin: 15px;
}

// Switch button //
.switch {
	display: none;

	+ .switch-btn {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
		user-select: none;

		&:after,
		&:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .switch-btn:after {
		left: 50%;
	}
}

.switch-light {
	+ .switch-btn {
		background: #f0f0f0;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;

		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}

	&:checked + .switch-btn {
		background: $valide;
	}
}

// Bottom right create btn //

.btn {
	align-items: center;

	ion-icon {
		margin-right: 7.5px;
	}
}

.create-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	cursor: pointer;
	color: white;
	padding: 15px 0;
	width: 120px;
	border: 0 !important;
	border-radius: 30px !important;
	box-shadow: $box-shadow !important;
	position: fixed;
	right: 30px;
	bottom: 15px;
	text-transform: capitalize;
	text-decoration: none;
	transition: $transition;
	font-size: 16px;

	&:hover {
		color: white !important;
	}

	ion-icon {
		font-size: 18px;
	}
}

// For disabled links //
.disabled-link {
	color: $brand-main2;
	cursor: default;

	&:hover {
		color: $brand-main2;
		cursor: default;
	}
}

// Pagination //

.pagination {
	justify-content: center;

	.page-item.active {
		.page-link {
			z-index: 3;
			color: #fff;
			background-color: $brand-main1;
			border-color: $brand-main1;
		}
	}

	.page-link {
		z-index: 3;
		color: $brand-main1;
		border: $brand-main2;

		&:hover {
			color: $brand-main1;
		}
	}

}

// Filtres //


.table {
	td,
	th {
		vertical-align: middle;
	}

	a {
		text-decoration: none;
	}

	.dropdown-menu {
		border: 0 !important;
		border-radius: 10px !important;
		box-shadow: $box-shadow !important;
		padding: 0;
		left: auto;

		a {
			cursor: pointer;

			&:hover {
				text-decoration: none;
				color: lighten($brand-main1, 10%) !important;
			}
		}

		.btn-filter {
			display: flex;
			justify-content: center;
			width: 100%;
			border: none;
			background: none;
			padding: 7.5px 3.75px;
			transition: $transition;

			&:hover {
				background: darken(#fff, 10%);
			}
		}

		ul {
			overflow-x: auto;
		}

		.filter {
			margin: 0;
			padding: 0;
			overflow-x: auto;
			max-height: 400px !important;

			hr {
				margin: 0;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				align-content: center;
				padding: 0 30px;

				label {
					font-size: 12px;
					margin: 0 !important;
					padding: 0;
				}

			}
		}
	}
}

// Dropdown parameters for filters //
.filter-dropdown {
	.dropdown-menu {
		width: 15rem;
	}
}

// Avoid dropdown issues on empty table //
.table-responsive {
	min-height: 70vh;
}

// Checkbox //
input[type="checkbox"] + .label-text:before {

	content: "x";
	background-color: red;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	width: 1em;
	display: inline-block;
	margin-right: 5px;
}

input[type="checkbox"]:checked + .label-text:before {
	content: "x";
	color: #2980b9;
	background-color: red;
	animation: effect 250ms ease-in;
}


.checkbox {
	display: flex;
	align-items: center;

	input {
		margin-right: 7.5px;
	}
}

@keyframes effect {
	0% {
		transform: scale(0);
	}
	25% {
		transform: scale(1.3);
	}
	75% {
		transform: scale(1.4);
	}
	100% {
		transform: scale(1);
	}
}

// Checkbox sections //

#rubrique {
    span {
	line-height: 1rem;
    }
}

// Images sections //
#client-images {
    .img-container {
        .overlay {
            margin-top: 15px;
            height: calc(100% - 15px);
            width: calc(100% - 30px);
            border-radius: 5px;
            position: absolute;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            transition: $transition;

            ion-icon {
                visibility: hidden;
                opacity: 0;
                font-size: 30px;
                cursor: pointer;
                color: white;
                transition: $transition; 
            }
            .open {
                transition: $transition;

                &:hover {
                    color: $valide;
                }
            }

            .delete {
                transition: $transition;

                &:hover {
                    color: $error;
                }
            }
            &:hover {
                background: #161616a6;

                ion-icon {
                    opacity: 1;
                    visibility: visible;
                }
            }

			.isvisible {
				opacity: 1 !important;
				visibility: visible !important;
			}
        }
		.overlay-inprogress {
			background: #161616a6;
		}

        img {
            display: block;
            height: 200px;
            width: 100%;
            margin-top: 15px;
            border-radius: 5px;
            object-fit: cover;
            box-shadow: $box-shadow;
        }
    }

    .add-img {
        display: flex;
		flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: auto;
        margin-top: 15px;
        border-radius: 5px;
        transition: $transition;

        ion-icon {
            font-size: 30px;
            font-weight: 100;
            color: $valide;
            transition: $transition;
        }

        &:hover {
            cursor: pointer;
            box-shadow: $box-shadow;
            background: $body-bg;

            ion-icon {
                color: lighten($valide, 10%);
            }
        }
    }

    .modal {
    background: transparentize($black, .1);
        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            margin-right: -15px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
            transition: $transition;    
            a {
                visibility: hidden;
                opacity: 0;
                font-size: 50px;
                cursor: pointer;
                color: white;
                transition: $transition;
            }
            &:hover {
                background: #161616a6;
    
                a {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        img {
            display: block;
            height: 80vh;
            width: 60vw;
            border-radius: 5px;
            object-fit: cover;
            box-shadow: $box-shadow;
        }
    }
}

#nav-anchor{
	text-align: center;
	font-variant: small-caps;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
}
