// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Dimensions
$sidebar-min-width: 230px;

//Autres
$box-shadow: rgba(22, 22, 22, 0.3) 0px 2px 8px 0px;
$transition: all 0.3s ease-in-out 0s;

// Colors
$brand-main1: #d92816;
$brand-main2: #a59eaf;
$black: #161616;
$error: #FF3B5E;
$valide: #41b961;
$blue: $brand-main1;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #FF3B5E;
$orange: #f6993f;
$yellow: #ffed4a;
$green: $valide;
$teal: #4dc0b5;
$cyan: #6cb2eb;
